import { onClickOutside } from '../utils/on-click-outside';
import { isDesktop } from '../constants/breakpoints';
import form from '../utils/form';

let modalIsOpen = false;
let eventHandler: (e: MouseEvent) => void;
let modalEl: HTMLElement;
let modalClose: HTMLButtonElement;

document.addEventListener('DOMContentLoaded', function() {
  modalEl = document.querySelector('.m-modal--bottom') as HTMLElement;
  modalClose = modalEl.querySelector('.m-modal__close') as HTMLButtonElement;

  modalClose.addEventListener('click', hideModal);

  // Show modal if newsletter form has been submitted
  if (window.location.hash === '#contact_form') showModal();

  const formEl = modalEl?.querySelector('.m-customer__form') as HTMLFormElement;
  const formErrors = modalEl?.querySelector('.m-customer__errors') as HTMLElement;

  if (formEl && formErrors) {
    // form.handleSubmit(formEl, formErrors, () => console.log('sent'));
  }
});

// Helpers
const scrollToBotton = (event: TransitionEvent) => {
  if (event.propertyName === 'grid-template-rows') {
    modalEl.removeEventListener('transitionend', scrollToBotton);
    window.scrollTo(0, document.body.scrollHeight);
  }
};

const showModal = () => {
  if (modalIsOpen) return;

  // Scroll to the bottom of the page on desktop
  if (isDesktop()) {
    modalEl.addEventListener('transitionend', scrollToBotton);
  }

  document.removeEventListener('click', eventHandler);
  document.body.classList.add('modal-bottom-is-open');
  modalIsOpen = true;

  eventHandler = onClickOutside(modalEl, hideModal);
}

const hideModal = () => {
  document.body.classList.remove('modal-bottom-is-open');
  document.removeEventListener('click', eventHandler);
  modalIsOpen = false;
}

export const modal = {
  showModal
};

export default modal;